
import { defineComponent, ref, computed, onMounted, reactive, h , nextTick, toRefs } from 'vue';
import { message as Message, Modal } from 'ant-design-vue'
import { useRouter } from 'vue-router'
import moment from "moment"
import { 
  jhManageContractList, 
  jhManageContractProvince, 
  jhManageContractSupervision,
  jhManageContractQuery,
  jhManageContractQueryProvince
} from '@/api/manage'
import _ from 'lodash'
const columns = [
  {
    title: '排名',
    dataIndex: '',
    key:'rowIndex',
    width:60,
    align:"center",
    customRender:function ({text,record,index}) {
      return parseInt(index)+1;
    }
  },
  {
    title:'省份',
    align:"center",
    width: 80,
    dataIndex: 'userName'
  },
  {
    title:'创建时间',
    align:"center",
    width:120,
    dataIndex: 'createTime'
  },
  {
    title:'付款状态',
    align:"center",
    width:100,
    dataIndex: 'paymentStatus',
    slots: { customRender: 'paymentStatus' }
  },
  {
    title:'付款时间',
    align:"center",
    width:120,
    dataIndex: 'paymentTime'
  },
  {
    title: '付款方式',
    dataIndex: 'payType',
    align: 'center',
    width:100,
    key: 'payType',
    slots: { customRender: 'payType' },
  },
  {
    title:'物流状态',
    align:"center",
    width:100,
    dataIndex: 'deliveryStatus',
    slots: { customRender: 'deliveryStatus' }
  },
  {
    title:'物流单号',
    align:"center",
    width:100,
    dataIndex: 'deliverySn'
  },
  {
    title:'快递费',
    align:"center",
    width:100,
    dataIndex: 'freightAmount',
    customRender:function ({text}) {
      return !text ? "" : (text / 100) + '元'
    }
  },
  {
    title:'收件人姓名',
    align:"center",
    width:100,
    dataIndex: 'receiveName'
  },
  {
    title:'收件人电话',
    align:"center",
    width:120,
    dataIndex: 'receivePhone'
  },
  {
    title:'收件人地址',
    align:"center",
    width:180,
    dataIndex: 'receiveProvince',
    customRender:function ({text, record}) {
      return `${record.receiveProvince || ''}${record.receiveCity || ''}${record.receiveCounty || ''}${record.receiveAddress || ''}`
    }
  },
];
type FormData = {
  type: string;
  beginDate: string;
  endDate: string;
  bo: string;
}
export default defineComponent({
  name: 'PublicMark',
  mixins: [],
  props: {
  },
  components: {
  },
  setup(props, context) {
    const router = useRouter();
    const dataSource = ref<Array<Object>>([]);
    const summaryProvince = ref<Array<Object>>([]);
    const province = ref({});
    const pageNo = ref<number>(1);
    const pageSize = ref<number>(10);
    const total = ref<number>(10);
    const len = ref<number>(0);
    const text = ref<string>('');
    const loading = ref<boolean>(false);
    const state = reactive({
      title: '',
      year: null,
      type: '',
      query: {}
    })
    /* table选中keys*/
    const selectedRowKey = ref<Array<Object>>([]);
    /* table选中records*/ 
    const selectionRow = ref<Array<Object>>([]);
    // 翻页
    const handleTableChange = (pagination, filters, sorter) => {
      pageNo.value = pagination.current
      pageSize.value = pagination.pageSize
    }
    function getJhManageContractList(data?: FormData) {
      if (!data.type) {
        Message.warning('链接失效')
        return
      };
      state.type = data.type
      var get_fn = null
      if (data.type == 'nationwide') {
        get_fn = jhManageContractList
      } else if (data.type == 'province') {
        get_fn = jhManageContractProvince
      } else if (data.type == 'supervision') {
        get_fn = jhManageContractSupervision
      }
      
      loading.value = true
      
      get_fn(data).then(res=>{
        if (res.params) {
          if (data.type == 'province') {
            summaryProvince.value = res.summaryProvince
            province.value = res.params
          } else {
            let arr = []
            len.value = res.params.length
            let y = (len.value - (len.value % 50)) / 50
            for (let index = 1; index < (y + 1); index++) {
              const elemt = index
              arr.push({
                num: (elemt*50-50 + 1) + '-' + elemt*50,
                list: res.params.slice(elemt*50-50, elemt*50),
              })
            }
            const x = len.value % 50
            if (x > 0) {
              arr.push({
                num: (len.value - x + 1) + '-' + len.value,
                list: res.params.slice((len.value - x), len.value),
              })
            }
            dataSource.value = arr
          } 
          text.value = res.text
          state.title = res.title
          state.year = res.year
        }
      }).finally(() => {
        loading.value = false
      })
    }
    const pagination = computed(() => ({
      current: pageNo.value,
      pageSize: pageSize.value,
      pageSizeOptions: ['10', '20', '30'],
      showTotal: (total, range) => {
        return range[0] + "-" + range[1] + " 共" + total + "条"
      },
      showQuickJumper: true,
      showSizeChanger: true,
      total: total.value
    }));
    const success = (elem) => {
      if (!elem.companyId) {
        Message.warning('没有公司id')
        return;
      }
      jhManageContractQuery({ companyId: elem.companyId }).then(res => {
        if (res) {
          let content = res.name + '，'
          if (res.birthday) {
            content = content + '出生于' + res.birthday + '，'
          }
          if (res.nation) {
            content = content + '民族' + res.nation + '，'
          }
          if (res.nativePlace) {
            content = content + '籍贯' + res.nativePlace + '，'
          }
          
          if (res.school) {
            content = content + '毕业于' + res.school + '，'
          }
          if (res.firstProfession) {
            content = content + res.firstProfession + '专业' + '。'
          }
          if (res.workDate) {
            content = content + '有' +  res.workDate + '年工作经验' + '，'
          }
          if (res.positionalTitles) {
            content = content + '取得了' + res.positionalTitles + '职称，'
          }
          if (res.certificate) {
            content = content + res.certificate + '证书。'
          }
          if (res.industry) {
            content = content + '其团队主营' + res.industry + '行业，'
          }
          if (res.works && res.works.length) {
            let works = ''
            for (let index = 0; index < res.works.length; index++) {
              const element = res.works[index];
              works += element + '；'
              if (index === 2) break;
            }
            content = content + '代表性业绩有' + works + '等项目'
          }
          Modal.success({ 
            title: '负责人简介',
            content: h('div', { }, [
              h('div', content),
            ]),
            centered: true
          });
        } else {
          Message.warning('没有个人信息')
        }
        
      })
      
    };
    const toProvince = (elem) => {
      if (!elem.province) {
        Message.warning('没有省')
        return;
      }
      router.push({
        path: '/province',
        query: {
          province: elem.province,
          ...state.query
        }
      })
    };
    const tables = ref(null)
    nextTick(() => {
      window.onresize = _.debounce(function () {
        const $app = tables
        const standardScale = 1080 / 1920
        console.log('translate-------------')
        const docHeight = document.body.clientHeight
        const docWidth = document.body.clientWidth

        if (docWidth < 1680) {
          const currentScale = docHeight / docWidth
          let [scale, translate] = [0, '']
          if (currentScale < standardScale) {
            // 以高度计算
            scale = docHeight / 1080
            const shouleWidth = 1920 * scale
            const offsetWidth = docWidth - shouleWidth
            translate = offsetWidth > 0 ? `translate(${offsetWidth / 2}px, 0)` : ''
          } else {
            // 以宽度计算
            scale = docWidth / 1920
            const shouleHeight = 1080 * scale
            const offsetHeight = docHeight - shouleHeight
            translate = offsetHeight > 0 ? `translate(0, ${offsetHeight / 2}px)` : ''
          }
          
          $app.value.style.cssText = `
          transform: scale(${scale}) ${translate};
          transform-origin: top left;
          min-width: 1920px;
          min-height: 1080px;
        `
        } else {
          $app.value.style.cssText = ''
        }
      })

    })
    onMounted(() => {
      const data = router.currentRoute.value.query as FormData
      state.query = data
      if (data) {
        getJhManageContractList(data)
      }
      const $app = tables
      const standardScale = 1080 / 1920
      const docHeight = document.body.clientHeight
      const docWidth = document.body.clientWidth
      
      if (docWidth < 1680) {
        const currentScale = docHeight / docWidth
        let [scale, translate] = [0, '']
        if (currentScale < standardScale) {
          // 以高度计算
          scale = docHeight / 1080
          const shouleWidth = 1920 * scale
          const offsetWidth = docWidth - shouleWidth
          translate = offsetWidth > 0 ? `translate(${offsetWidth / 2}px, 0)` : ''
        } else {
          // 以宽度计算
          scale = docWidth / 1920
          const shouleHeight = 1080 * scale
          const offsetHeight = docHeight - shouleHeight
          translate = offsetHeight > 0 ? `translate(0, ${offsetHeight / 2}px)` : ''
        }
        
        $app.value.style.cssText = `
        transform: scale(${scale}) ${translate};
        transform-origin: top left;
        min-width: 1920px;
        min-height: 1080px;
      `
      } else {
        $app.value.style.cssText = ''
      }
    })
    return {
      ...toRefs(state),
      columns,
      dataSource,
      pagination,
      summaryProvince,
      pageNo, pageSize, total, loading, selectedRowKey, selectionRow,
      handleTableChange,
      text,
      len, province, success, toProvince, tables,
      moment
    };
  },
});
